<template>
  <ch-layout class="basicInformation_wrapper" page>
    <template #default>
      <div class="main-wrapper">
        <div class="main">
          <p class="title" style="margin-left: 16px"> {{ model.name||'暂无' }}</p>
          <el-divider></el-divider>
          <main>
            <ch-form
                :render-option="option" :model="model"
                ref="modalForm" :splice="[6,6]" class="text_form_wrapper"
                :props="{ labelWidth: '140px', paddingRight: '0px', paddingLeft: '0px'}">
              <template #sms>
                <div class="balance_father">
                  <div class="balance">
                    <span>￥</span>
                    <span style="color: red">{{ rechargeObj.deposit ||'0.00' }}</span>
                    <ch-button type="link" :class="{opacity: !needRecharge }" @click="openModal">充值</ch-button>
                    <ch-button type="link" @click="openDepositDetails">充值明细</ch-button>
                  </div>
                  <div class="content">
                    <i class="el-icon-question question"></i>
                    <div class="text">您需在合同签订后支付保证金，保证金额度根据资质进行判定，保证金以服务商所属企业为准。</div>
                  </div>
                </div>

              </template>

              <template #balance>
                <div class="balance_father">
                  <div class="balance">
                    <span>￥</span>
                    <span class="yuan">{{ rechargeObj.walletBalance || '0.00' }}</span>
                    <span>元</span>
<!--                    <ch-button type="link">提现</ch-button>-->
                    <ch-button type="link" @click="openWalletDetails">钱包明细</ch-button>
                  </div>
                </div>
              </template>
            </ch-form>
          </main>
        </div>
      </div>
      <recharge-modal ref="rechargModal" />
      <deposit-details ref="depositDetails" />
      <wallet-detail ref="walletDetail" />
    </template>
    <template #page>
      <div class="deal">
        <span>我已经阅读并同意</span>
        <router-link  :to="{ name: 'userAgreement', query: { id: 26 } }" target="_blank" style="color: #6495ff">
          《万响用户协议》
        </router-link>
        <router-link to="disclaimerAgreement" target="_blank" style="color: #6495ff">
          《保证金协议规则》
        </router-link>
      </div>
    </template>

  </ch-layout>
</template>

<script>
import {mapGetters,mapActions} from "vuex";
import rechargeModal from "./rechargeModal.vue";
import depositDetails from "./depositDetails.vue";
import walletDetail from "./walletDetail.vue";
export default {
  components: {
    rechargeModal,depositDetails,walletDetail
  },
  data(){
    return{
      needRecharge: false,
      rechargeObj: {},
    }
  },
  computed:{
    option: function () {
      return [
        {type: 'image', label: '营业执照：', src: this.model.certificate?.split()},
        {type: 'image', label: '公司logo：', src: this.model.logo?.split()},
        {type: 'text', label: '公司名称：', prop: 'name', },
        {type: 'text', label: '公司简称：', prop: 'abbreviation', },
        {type: 'text', label: '服务类型：', prop: 'serveTypeStr', },
        {type: 'text',  label: '服务城市：',prop: 'area'},
        {type: 'text', label: '公司法人：', prop: 'legalPerson', },
        {type: 'text', label: '公司电话：', prop: 'companyTel',  },
        {type: 'text', label: '公司地址：', prop: 'address',  },
        {type: 'text', label: '开户行：', prop: 'openingBank', },
        {type: 'text', label: '银行账户：', prop: 'bankAccount', },
        {type: 'text', label: '社会信用代码：', prop: 'creditCode',  },
        {type: 'text', label: '负责人姓名：', prop: 'leader',  },
        {type: 'text', label: '负责人手机号：', prop: 'leaderPhone', },
        {type: 'text', label: '负责人电子邮箱：', prop: 'leaderEmail',  },
        {type: 'slot', label: '当前保证金(元)：',slotName: 'sms',  },
        {type: 'slot', label: '钱包余额(元)：',slotName: 'balance',  },

      ]
    },
    model: function () {
      if(this.company){
        console.log(this.company)
        return this.company
      } else {
        return {
          companyName: '暂无',
          cashBalance: '0.00',
          smsNumber: '0.00',
          giftBalance: '0.00',
        }
      }
    },
    ...mapGetters(['company'])
  },
  created(){
    this.getCompany(true)
    this.getNeedRecharge()
  },
  methods:{
    ...mapActions(['getCompany']),
    // 充值
    openModal(){
      if(!this.needRecharge) return
      this.rechargeObj.companyName = this.model.name
      this.$refs.rechargModal.openModal(this.rechargeObj)
    },
    // 充值明细
    openDepositDetails(){
      this.$refs.depositDetails.openModal()
    },
    // 获取是否充值
    getNeedRecharge(){
      this.$curl.get('/hm/hmCompanyDeposit/needRecharge').then((res)=>{
        this.rechargeObj = res.data
        this.needRecharge = this.rechargeObj.needRecharge
      })
    },
    // 钱包明细
    openWalletDetails(){
      this.$refs.walletDetail.openModal()
    }
  }
}
</script>

<style scoped lang="scss">
.basicInformation_wrapper {
  .main-wrapper {
    height: 100%;
    overflow: auto;
    .main {
      margin: 2% 5%;
      > main {
        // 表单切片的样式
        ::v-deep .form_splice_wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        // 表单 独立一列 样式
        ::v-deep .form_column_wrapper {
          width: 100%;
        }
        // 让查看变浅色
        ::v-deep .text_form_wrapper {
          .el-form-item__label {
            color: #9C9EA7;
          }
        }
      }
      .balance_father{
        width: 175px;
        display: flex;
        flex-direction: column;
        .balance {
          color: red;
          .question {
            color: #999;
            font-size: 18px;
            vertical-align: middle;
            cursor: pointer;
          }
          .opacity{
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        .content{
          position: relative;
          line-height: normal;
          .question {
            position: absolute;
            right: 20px;
            top: -30px;
            color: #999;
            font-size: 18px;
            cursor: pointer;
            display: block;
          }
          .text {
            width: 300px;
            color: red;
            display: none;
            padding-bottom: 10px;
            font-size: 12px;
            white-space: break-spaces;
          }
          .question:hover + .text{
            display: block;
          }
        }
      }


      .title {
        margin: 20px 0;
        text-align: center;
        font-size: 24px;
      }

      .deal {
        text-align: center;
        span{
          color: #9D9D9D;
        }
      }
    }
  }

  .button {
    margin-bottom: 60px;
    /* 按钮样式 */
    ::v-deep .el-button--primary {
      /* 确认按钮 */
      border: 1px solid #3e7eff;
      background: #3e7eff;
      color: #ecf5ff;
      &:focus, &:hover {
        /* 确认按钮放上样式 */
        background: #66b1ff;
        border-color: #66b1ff;
        color: #FFF;
      }
      &.is-plain {
        /* 界面按钮样式 */
        background: #ecf5ff;
        border-color: #3e7eff;
        color: #3e7eff;
        &:hover {
          /* 界面按钮放上样式 */
          background: #3e7eff;
          border-color: #3e7eff;
          color: #fff;
        }
      }
    }
  }
}
</style>